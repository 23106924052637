<template>
  <div id="app">
    <Auth :show="showAuth" />
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="info"
    >
      <b-navbar-nav
        v-for="(route, i) in getMenuItems"
        :key="i"
      >
        <!-- Single nav item, without children -->
        <b-nav-item
          v-if="!route.children && (route.meta.visible_for_all || is_admin)"
          class="nowrap"
          :href="`/#${route.path}`"
        >
          {{ route.name }}
        </b-nav-item>

        <!-- Drop-down nav item, who has children -->
        <b-nav-item-dropdown
          v-if="route.children && (route.meta.visible_for_all || is_admin)"
          :text="route.name"
          right
        >
          <b-dropdown-item
            v-for="(subitem, j) in route.children"
            :key="j"
            :href="`/#${subitem.path}`"
          >
            {{ subitem.meta.title }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- logout button, visible if user is authorized -->
      <b-navbar-nav
        v-if="is_authorized"
        class="full-width"
        align="right"
      >
        <b-nav-item @click="logout()"> Logout</b-nav-item>
      </b-navbar-nav>
      <!-- log in button, visible if user is not authorized -->
      <b-navbar-nav
        v-else
        class="full-width"
        align="right"
      >
        <b-nav-item @click="login()"> Log In</b-nav-item>
      </b-navbar-nav>
      <!-- login/logout group end -->
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  data() {
    return {showAuth: false};
  },
  computed: {
    ...mapGetters(['is_authorized', 'is_admin', 'needsAuth']),
    routes() {
      return this.$router.options.routes;
    },
    getMenuItems() {
      return this.$router.options.methods.getMenuItems();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Silver Bassoon';
      },
    },
    is_authorized: {
      handler(auth) {
        this.showAuth = !auth && this.needsAuth;
      },
      immediate: true,
    },
    needsAuth: {
      handler(auth) {
        this.showAuth = auth && !this.is_authorized;
      },
      immediate: true,
    },
  },
  mounted() {
    // The current user is stored at local storage in the JSON
    // format, therefore it can't be retrieved as a simple variable.

    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      try {
        currentUser = JSON.parse(currentUser);
        this.$store.commit('currentUser', currentUser);
      } catch (e) {
        this.$store.commit('currentUser', null);
      }
    }

    // console.log('App mounted, is_authorized=' + this.is_authorized + ', is_admin=' + this.is_admin);
  },

  methods: {
    login() {
      this.showAuth = true;
    },
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style>
body {
  margin: 0;
  font-family: 'Neue Montreal', 'Helvetica Neue', Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  font-size: 16px;
  color: #222;
  background-color: #f1f2f4;
}

#app {
  margin-bottom: 40px;
}

.container {
  margin-top: 40px;
}

.full-width {
  width: 100%;
}

.nowrap {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

div.modal-dialog button.btn {
  width: 85px;
  min-width: 85px;
  max-width: 85px;
}

table tbody tr td a,
span a {
  color: #006b21;
}

.busy {
  background: url(./images/spinner.gif) no-repeat;
  background-size: contain;
  background-position: center;
}

.btn-secondary.busy {
  background-color: #4a5055;
  border-color: #4a5055;
  color: gray;
}

div.formulate-input-element input {
  width: 100%;
  margin-bottom: 10px;
}

div.formulate-input-element select {
  margin-bottom: 10px;
}

div.formulate-input-element--submit {
  float: right;
  margin-top: 20px;
}

div.formulate-input-element--submit button {
  background-color: #0069d9;
  border: none #0069d9;
  color: white;
  min-height: 40px;
  min-width: 80px;
  margin-left: 5px;
  margin-right: 5px;
}

ul.formulate-input-errors {
  color: darkred;
}

ul.formulate-input-errors li {
  font-size: 12px;
  margin-top: 5px;
}

.form-group[data-type='hidden'] {
  margin-bottom: 0;
}
</style>
