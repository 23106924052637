import {toGetters} from '@/utils';

const state = {
    candidates: [],
    freelancers: [],
    managers: [],
    users: [],
    skills: [],
    skill_categories: [],
    questions: [],
    question_categories: [],
};

const withPhoto = (self, obj) => ({
    ...obj,
    photo: obj.photo ? `${self._vm.$hostname}${obj.photo}` : null,
});

export default {
    state,
    getters: toGetters(state),
    mutations: {
        genericGot(state, {value, genericField}) {
            if (genericField === 'freelancers')
                value.result = value.result.map((obj) => withPhoto(this, obj));
            state[genericField] = value.result;
        },

        genericCreated(state, {value, genericField}) {
            const arr = state[genericField];
            let {result} = value;
            if (genericField === 'freelancers')
                result = withPhoto(this, result);

            let found = false;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id === result.id) {
                    arr.splice(i, 1, result);
                    found = true;
                }
            }
            if (!found) arr.push(result);
        },

        genericUpdated(state, {value, genericField}) {
            let {result} = value;
            if (genericField === 'freelancers')
                result = withPhoto(this, result);

            for (const [i, item] of state[genericField].entries()) {
                if (item.id === result.id || item.id === result.old_id) {
                    state[genericField].splice(i, 1, {
                        ...item,
                        ...result,
                    });
                    return;
                }
            }
            // Fall through: not found
            console.error(`${genericField}: element not found`);
            console.log(state[genericField]);
            console.log(value);
        },

        genericDeleted(state, {value, genericField}) {
            state[genericField] = state[genericField].filter(
                (item) => item.id !== value.result.id,
            );
        },
    },
};
