import App from '@/App.vue';
import {config} from '@/config';
import router from '@/router';
import Auth from '@/views/shared/Auth';
import Wrapper from '@/views/shared/Wrapper';
import VueFormulate from '@braid/vue-formulate';
import {BootstrapVue} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import Vue from 'vue';

import store from './store';

import '@/assets/theme.scss'; // Import your global SCSS file

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(VueFormulate, {
    classes: {
        outer: 'form-group',
        input: 'form-control',
        inputHasErrors: 'is-invalid',
        help: 'form-text text-muted',
        errors: 'list-unstyled text-danger',
    },
    useInputDecorators: false,
    uploader: (file, progress) => {
        // optionally handle the `file` for your own purposes here...
        progress(100);
        return Promise.resolve({});
    },
});

Vue.config.productionTip = false;
// specify the production hostname if needed
// Vue.prototype.$hostname = Vue.config.productionTip
//     ? 'http://localhost:5000'
//     : 'http://localhost:5000';
Vue.prototype.$hostname = 'https://app.1gency.ee';
Vue.prototype.appConfig = config;

Vue.component('Wrapper', Wrapper);
Vue.component('Auth', Auth);

new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount('#app');
