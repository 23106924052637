import {toGetters} from '@/utils';
import axios from 'axios';

const state = {
    isLoading: false,
    fetchingError: '',
};

export default {
    state,
    getters: toGetters(state),
    mutations: {
        isLoading(state, value) {
            state.isLoading = value;
        },
        fetchingError(state, value) {
            state.fetchingError = value;
        },
    },

    actions: {
        // FIXME: complexity
        // eslint-disable-next-line sonarjs/cognitive-complexity
        async _callAPI(
            ctx,
            {action, method, path, mutation, parameters, genericField},
        ) {
            const {is_authorized, needsAuth} = ctx.rootGetters;
            if (!is_authorized && !(!needsAuth && method === 'get')) return;

            // console.log(
            //   '_callAPI, method=' + method + ', path=' + path
            //   + ', mutation=' + mutation
            //   + ', is_authorized=' + is_authorized
            // );
            // console.log(parameters);

            if (!parameters?.suppressLoading) ctx.commit('isLoading', true);

            const auth_headers = {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            };
            const url = `${this._vm.$hostname}${path}`;

            try {
                let response = null;
                let data = null;
                if (method === 'get') {
                    response = await axios.get(url, {
                        headers: auth_headers,
                    });
                } else if (method === 'post' || method === 'put') {
                    response = await axios[method](url, parameters, {
                        headers: auth_headers,
                    });
                } else if (method === 'delete') {
                    response = await axios[method](url, {
                        data: parameters,
                        headers: auth_headers,
                    });
                } else {
                    console.error('_callAPI: unhandled method ' + method);
                }
                data = response?.data;

                //console.log('after _callAPI, path=' + path + ', data=');
                //console.log(data);
                if (action) ctx.dispatch(action, data);
                else if (genericField)
                    ctx.commit(mutation, {value: data, genericField});
                else ctx.commit(mutation, data);
                ctx.commit('fetchingError', '');
            } catch (e) {
                if (e.response?.status === 401) ctx.dispatch('logout');

                if (e?.response?.data?.message)
                    ctx.commit('fetchingError', e.response.data.message);
                else if (e.message) ctx.commit('fetchingError', e.message);
                else ctx.commit('fetchingError', e.toString());
            } finally {
                if (!parameters?.suppressLoading)
                    ctx.commit('isLoading', false);
            }
        },

        fetch(ctx, {path, mutation, parameters, genericField, action}) {
            ctx.dispatch('_callAPI', {
                method: 'get',
                path,
                mutation,
                parameters,
                genericField,
                action,
            });
        },

        async crudCreate(
            ctx,
            {path, mutation, parameters, genericField, action},
        ) {
            await ctx.dispatch('_callAPI', {
                method: 'post',
                path,
                mutation,
                parameters,
                genericField,
                action,
            });
        },

        async crudUpdate(
            ctx,
            {path, mutation, parameters, genericField, action},
        ) {
            await ctx.dispatch('_callAPI', {
                method: 'put',
                path,
                mutation,
                parameters,
                genericField,
                action,
            });
        },

        async crudDelete(
            ctx,
            {path, mutation, parameters, genericField, action},
        ) {
            await ctx.dispatch('_callAPI', {
                method: 'delete',
                path,
                mutation,
                parameters,
                genericField,
                action,
            });
        },
    },
};
