import {getLocalTimestamp, toGetters} from '@/utils';

const state = {
    interviews: {},
};

const _addName = (interview) => ({
    ...interview,
    freelancerName: interview.freelancerName || interview.freelancerRid,
    managerName: interview.managerName || interview.createdByUserUID,
    interview_at_local: getLocalTimestamp(interview.interview_at),
});

export default {
    state,
    getters: toGetters(state),
    mutations: {
        interviews(state, data) {
            state.interviews = Object.fromEntries(
                data.result
                    .map(_addName)
                    .map((int) => [int.applicationUID, int]),
            );
        },

        // Commited when the row corresponding to given uid has sent a request
        busyStarted(state, applicationUID) {
            state.interviews[applicationUID] = {
                ...state.interviews[applicationUID],
                busy: true,
            };
        },

        // Commited when a "mark read" request is fulfilled
        interviewsModified(state, data) {
            const got_items = data?.result || [];
            for (const got_item of got_items.map(_addName))
                state.interviews[got_item.applicationUID] = got_item;
        },
    },
};
