import Vue from 'vue';
import Vuex from 'vuex';

import admin from './modules/admin';
import auth from './modules/auth';
import generic from './modules/generic';
import interviews from './modules/interviews';
import job from './modules/job';
import loading from './modules/loading';
import proposals from './modules/proposals';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        loading,
        admin,
        interviews,
        proposals,
        generic,
        job,
    },
});
