import {toGetters} from '@/utils';

const state = {
    jobIDs: [],
    idsBlob: '',
};

export default {
    state,
    getters: toGetters(state),
    mutations: {
        jobIDs(state, data) {
            const {result} = data;
            const file = new Blob([JSON.stringify(result)], {type: 'json'});
            state.idsBlob = URL.createObjectURL(file);
            state.jobIDs = result;
        },
    },
};
