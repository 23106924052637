import {SKILL_LEVELS} from '@/constants';
import moment from 'moment';
import {assoc, flip, mapObjIndexed, prop} from 'rambda';

export const toTitle = (val) => `https://www.upwork.com/jobs/~${val}`;

export const customCompare =
    ({by_title = [], by_time = []}) =>
    (itemA, itemB, key, isDesc) => {
        const a = itemA[key];
        const b = itemB[key];
        // Sort nulls last.
        if (!a) return isDesc ? -1 : 1;
        else if (!b) return isDesc ? 1 : -1;
        else if (by_title.includes(key))
            return a.localeCompare(b, undefined, {numeric: true});
        else if (by_time.includes(key))
            return moment(itemA[key]) - moment(itemB[key]);
        // if field's key is not in custom_sort_keys we let b-table handle the sorting
        else return false;
    };

export const toGetters = (state) => mapObjIndexed((_, p) => prop(p), state);
export const toMutations = (state) =>
    mapObjIndexed((_, p) => flip(assoc(p)), state);

const _padZerosToTwoPlaces = function (num) {
    return (num < 10 ? '0' : '') + num;
};
export const getDateAsString = function (timestamp) {
    if (timestamp == null) return '';
    const date = new Date(timestamp);
    const month = _padZerosToTwoPlaces(date.getMonth() + 1);
    const day = _padZerosToTwoPlaces(date.getDate());
    return date.getFullYear() + '-' + month + '-' + day;
};

export const getTimeAsString = function (timestamp) {
    if (timestamp == null) return '';
    const date = new Date(timestamp);
    const hours = _padZerosToTwoPlaces(date.getHours());
    const minutes = _padZerosToTwoPlaces(date.getMinutes());
    const seconds = _padZerosToTwoPlaces(date.getSeconds());
    return `${hours}:${minutes}:${seconds}`;
};

export const getDateTimeAsString = function (timestamp) {
    if (timestamp == null) return '';
    return getDateAsString(timestamp) + ' ' + getTimeAsString(timestamp);
};

export const user_local_offset_in_seconds =
    new Date().getTimezoneOffset() * 60 * 1000;

/** Convert UTC string date to local timestamp
 * @param value string date or datetime value
 * @returns {null|number}
 */
export const getLocalTimestamp = function (value) {
    if (value != null)
        return new Date(value).getTime() + user_local_offset_in_seconds;
    else return null;
};

export const skillLevelToEnumRepr = (level) => {
    return typeof level === 'number' ? SKILL_LEVELS[level].value : level;
};

export const reverseObjectKeyValue = (obj) => {
  const reversedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    reversedObj[value] = key;
  }
  return reversedObj;
};
