import {getLocalTimestamp, toGetters, reverseObjectKeyValue} from '@/utils';

const state = {
  proposals: [],
  proposalDetail: {},
  status_values: {},
  pipelines: {},
};

const _addFields = (p) => ({
  ...p,
  freelancerName: p.freelancerName || p.freelancerRid,
  managerName: p.managerName || p.createdByUserUID,
  timestamp_local: getLocalTimestamp(p.timestamp),
  updated_at_local: getLocalTimestamp(p.updated_at),
});

export default {
  state,
  getters: toGetters(state),
  mutations: {
    proposals(state, data) {
      const {status_values = {}, result = [], pipelines = {}} = data;

      state.status_values = reverseObjectKeyValue(status_values);
      state.proposals = result.map(_addFields);
      state.pipelines = reverseObjectKeyValue(pipelines);
    },

    proposalDetail(state, data) {
      state.proposalDetail = _addFields(data.result);
    },

    proposalModified(state, data) {
      const response = data?.response || false;
      if (response) {
        const the_id = response.id;
        const modifiedTs = response.modifiedTs;
        const index = state.proposals.findIndex(p => p.id === the_id);
        if (index !== -1) {
          state.proposals[index].updated_at_local = getLocalTimestamp(modifiedTs);
        }
      }
    },
  },
};
