<template>
    <b-modal
        v-model="modalVisible"
        title="Authorization"
        centered
        @ok="onOK"
    >
        <span class="auth-error-message"> {{ authError }} </span>

        <b-form>
            <b-form-group
                label="Username:"
                label-for="auth-input-username"
            >
                <b-form-input
                    id="auth-input-username"
                    v-model="form['username']"
                    autocomplete="username"
                />
            </b-form-group>
            <b-form-group
                label="Password:"
                label-for="auth-input-password"
            >
                <b-form-input
                    id="auth-input-password"
                    v-model="form['password']"
                    type="password"
                    autocomplete="current-password"
                />
            </b-form-group>
        </b-form>
    </b-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Auth',

    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                username: '',
                password: '',
            },
            modalVisible: false,
        };
    },
    computed: mapGetters(['authError', 'is_authorized', 'needsAuth']),

    watch: {
        is_authorized: {
            handler(authorized) {
                if (authorized) this.modalVisible = false;
                else this.modalVisible = this.needsAuth;
            },
            immediate: true,
        },
        show: {
            handler(val) {
                this.modalVisible = val;
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions(['fetchJWT']),
        onOK(evt) {
            evt.preventDefault();
            this.fetchJWT(this.form);
        },
    },
};
</script>

<style>
.auth-error-message {
    color: red;
    font-weight: bold;
    text-align: center;
}
</style>
