<template>
    <div class="container">
        <!-- <Auth /> -->
        <div
            v-if="isLoading"
            class="load-indicator"
        />
        <div
            v-if="!isLoading"
            class="row"
        >
            <div class="col">
                <div style="width: 100%; text-align: center">
                    <div
                        v-if="
                            addButton && (is_authorized || !authenticatedOnly)
                        "
                        style="float: right"
                    >
                        <b-button
                            size="sm"
                            @click="addButton.on_click()"
                        >
                            {{ addButton.title }}
                        </b-button>
                    </div>
                    <div
                        v-if="is_authorized || !authenticatedOnly"
                        style="float: right"
                    >
                        <slot name="extra-right" />
                    </div>

                    <div
                        v-if="selector && (is_authorized || !authenticatedOnly)"
                        style="float: left"
                    >
                        <FormulateInput
                            id="wrapper-selector"
                            type="select"
                            :value="selector.value"
                            :options="selector.options"
                            @change="selector.change"
                        />
                    </div>
                    <div
                        v-if="is_authorized || !authenticatedOnly"
                        style="float: left"
                    >
                        <slot name="extra-left" />
                    </div>

                    <h1 class="span-route-name">
                        {{ getTitle }}
                    </h1>
                </div>
                <div
                    v-if="Boolean(fetchingError)"
                    class="mt-4"
                >
                    <h4>Oops, something went wrong</h4>
                    <span> {{ fetchingError }} </span>
                </div>
                <div
                    v-else-if="(is_authorized || !authenticatedOnly) && hasData"
                    class="mt-4"
                >
                    <slot name="loaded" />
                </div>
                <div
                    v-else-if="!is_authorized && authenticatedOnly"
                    class="mt-5"
                >
                    <h4 class="h3 text-center">You are not authenticated.</h4>
                    <p class="h5 mt-3 text-center">
                        Please log in to view this page.
                    </p>
                </div>
                <div
                    v-else
                    class="mt-5"
                >
                    <h4 class="h3 text-center">No data</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Wrapper',
    props: {
        hasData: Boolean,

        addButton: {
            type: Object,
            default: null,
        },

        selector: {
            type: Object,
            default: null,
        },

        title: {
            type: String,
            default: null,
        },

        authenticatedOnly: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapGetters(['fetchingError', 'isLoading', 'is_authorized']),

        getTitle() {
            if (this.title) return this.title;
            return this.$route.name;
        },
    },
    watch: {
        authenticatedOnly: {
            // Simple way to propagate public page to Auth component
            handler(auth) {
                this.$store.commit('needsAuth', auth);
            },
            immediate: true,
        },
    },
};
</script>

<style>
.span-route-name {
    font-size: 28px;
    font-weight: bold;
}

div.load-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../../images/spinner.gif) no-repeat;
    background-size: 250px 250px;
    background-position: center;
    /* background: rgba(200, 200, 200, 0.7); */
}

#wrapper-selector {
    min-height: 35px;
    height: 35px;
    margin-top: 2px;
}
</style>
