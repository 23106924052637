import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const page_jobs = {
    path: '/',
    name: 'Jobs',
    component: () => import(/* webpackChunkName: "Jobs" */ '@/views/Jobs'),
    meta: {title: 'Jobs', visible_for_all: true},
};

const page_proposals = {
    path: '/proposals',
    name: 'Proposals',
    component: () =>
        import(/* webpackChunkName: "Proposals2" */ '@/views/Proposals'),
    meta: {title: 'Proposals', visible_for_all: true},
};

const page_questions = {
    path: '/questions',
    name: 'Questions',
    component: () => import(/* webpackChunkName: "Questions" */ '@/views/auto/Questions'),
    meta: {title: 'Questions'},
}

const page_users = {
    path: '/users',
    name: 'Users',
    component: () =>
        import(/* webpackChunkName: "Users" */ '@/views/auto/User'),
    meta: {title: 'Users'},
};

const page_freelancers = {
    path: '/freelancers',
    name: 'Freelancers',
    component: () =>
        import(/* webpackChunkName: "Freelancers" */ '@/views/auto/Freelancer'),
    meta: {title: 'Freelancers'},
};

const page_candidates = {
  path: '/candidates',
  name: 'Candidates',
  component: () =>
    import(/* webpackChunkName: "Freelancers" */ '@/views/auto/Candidate'),
  meta: {title: 'Candidates'},
};


const page_managers = {
    path: '/managers',
    name: 'Managers',
    component: () =>
        import(/* webpackChunkName: "Managers" */ '@/views/auto/Manager'),
    meta: {title: 'Managers'},
};

const page_skills = {
    path: '/skills',
    name: 'Skills',
    component: () =>
        import(/* webpackChunkName: "Skills" */ '@/views/SkillManagement'),
    meta: {title: 'Skills management'},
};

const routes = [
    page_jobs,
    page_proposals,
    page_candidates,
    page_users,
    page_freelancers,
    page_managers,
    page_skills,
    page_questions,
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    methods: {
        getMenuItems() {
            return [
                page_proposals,
                page_jobs,
                page_candidates,
                {
                    path: null,
                    name: 'Admin',
                    meta: {title: 'Admin'},
                    children: [
                        page_users,
                        page_freelancers,
                        page_managers,
                        page_skills,
                        page_questions,
                    ],
                },
            ];
        },
    },
});

export default router;
