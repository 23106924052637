import {toGetters} from '@/utils';
import {post} from 'axios';

const state = {
    authStatus: localStorage.getItem('jwt') ? 'SUCCESS' : 'NEED',
    currentUser: null,
    authError: '',
    is_authorized: !!localStorage.getItem('jwt'),
    // This prevents blinking of auth dialog, when it shouldn't pop up.
    // Wrapper commits needsAuth as soon as it's loaded, because it's the main
    // source of truth and can create (override) public pages which do not require auth.
    needsAuth: false,
};

export default {
    state,
    getters: {
        ...toGetters(state),

        showAuth(state) {
            return state.authStatus !== 'SUCCESS';
        },

        is_admin(state) {
            return !!(state.currentUser && state.currentUser.is_admin);
        },
    },
    actions: {
        async fetchJWT(ctx, form) {
            ctx.commit('is_authorized', false);
            ctx.commit('authStatus', 'WAIT');

            try {
                const {data} = await post(
                    `${this._vm.$hostname}/api/login/`,
                    form,
                );

                localStorage.setItem('jwt', data.token);

                ctx.commit('currentUser', data.current_user);
            } catch (e) {
                localStorage.removeItem('jwt');

                ctx.commit('currentUser', null);
                ctx.commit('authStatus', 'FAIL');

                // Error message output to the dialog box

                if (e?.response?.data?.message)
                    ctx.commit('authError', e.response.data.message);
                else if (e.message) ctx.commit('authError', e.message);
                else ctx.commit('authError', e.toString());
            }
        },

        logout(ctx) {
            localStorage.removeItem('jwt');
            ctx.commit('currentUser', null);
            ctx.commit('authStatus', 'NEED');
        },
    },
    mutations: {
        authStatus(state, v) {
            state.authStatus = v;
        },
        is_authorized(state, v) {
            state.is_authorized = v;
        },
        authError(state, v) {
            state.authError = v;
        },
        needsAuth(state, v) {
            state.needsAuth = v;
        },
        currentUser(state, v) {
            if (v) localStorage.setItem('currentUser', JSON.stringify(v));
            else localStorage.removeItem('currentUser');
            state.currentUser = v;
            state.is_authorized = !!v;
            if (v) {
                state.authStatus = 'SUCCESS';
                state.authError = '';
            }
        },
    },
};
