import {toGetters} from '@/utils';

const state = {
    jobs: [],
};

export default {
    state,
    getters: toGetters(state),
    mutations: {
        jobsGot(state, value) {
            const {jobs} = value.result;
            Object.assign(state, {jobs});
        },
    },
};
